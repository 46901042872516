import React from 'react';

import Heading from "../components/Heading.js";
import MuertosCatalog from "../components/MuertosCatalog.js";
import Footer from "../components/Footer.js";

export default function DiaMuertos(){
    return(
        <>
            <Heading hImage={`url('assets/images/fondo_muertos.jpg')`} hTitle={'Día de Muertos'}/>
            <MuertosCatalog />
            <Footer />
        </>
    )
}