import React from 'react'

import MuertosItem from './MuertosItem';
import MuertosList from '../muertos-list';

export default function VideosCatalog(){
    function createVideoContainer(muerto) {
        return (
            <MuertosItem 
                key={muerto.id}
                mLink={muerto.muertoLink}
                mName={muerto.muertoName}
            />
        );
    }
    return(
        <>
            <section className="ftco-section ftco-property-details">
                <div className="container">
                    <div className="row justify-content-center">
                        {MuertosList.map(createVideoContainer)}
                    </div>
                </div>
            </section>
        </>
    );
}