import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Index from "./views/Index.js";
import About from "./views/About.js";
import Services from "./views/Services.js";
import Projects from './views/Projects.js';
import Videos from './views/Videos.js';
import Sustainability from './views/Sustainability.js';
import Covid19 from './views/Covid19.js';
import Contact from './views/Contact.js';
import DiaMuertos from './views/DiaMuertos.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/acerca" element={<About />} />
      <Route path="/servicios" element={<Services />} />
      <Route path="/proyectos" element={<Projects />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/sostenibilidad" element={<Sustainability />} />
      <Route path="/covid" element={<Covid19 />} />
      <Route path="/contacto" element={<Contact />} />
      <Route path="/diaDeMuertos" element={<DiaMuertos />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);

