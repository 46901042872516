import React from 'react'

export default function MuertosItem(props){
    return(
        <>
            <div className="col-md-6">
                <div className="property-details">
                    <div className="text">
                        <span className="subheading">{props.mName}</span>
                    </div>
                    <video key={props.id} width="590" height="340" controls>
                        <source src={props.mLink} type="video/mp4"/>
                    </video>
                </div>
            </div>
        </>
    );
}