const proyectos = [
    {id: 1,
        muertoLink: "assets/videos/equipo1.mp4",
        muertoName: "Equipo 1: Altar Ing. Saúl Román",
    },
    {id: 2,
        muertoLink: "assets/videos/equipo2.mp4",
        muertoName: "Equipo 2: Altar Juan Roberto Estrada Gil",
    },
    {id: 3,
        muertoLink: "assets/videos/equipo3.mp4",
        muertoName: "Equipo 3: Altar Antonio Aguilar",
    },
    {id: 4,
        muertoLink: "assets/videos/equipo4.mp4",
        muertoName: "Equipo 4: Altar Melecio Amador",
    },
    {id: 5,
        muertoLink: "assets/videos/equipo5.mp4",
        muertoName: "Equipo 5: Altar Leteo",
    },
    {id: 6,
        muertoLink: "assets/videos/equipo6.mp4",
        muertoName: "Equipo 6: Altar Vicente Marquez Sosa",
    }
  ];
  
export default proyectos;